import clsx from 'clsx'
import React, { useCallback, useState } from 'react'
import type { Optional } from 'types/strict-null-helpers'

import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useCart } from '~/components/providers/CartProvider/CartProvider'
import { CartProviderProductErrorCode } from '~/lib/types/cart.interface'
import CheckIcon from '~/components/primitives/icons/CheckIcon'
import QuickAddToCartIcon from '~/components/primitives/icons/QuickAddToCartIcon'
import type { QuickAddToCartData } from '~/lib/types/analytics.interface'
import { useAnalytics } from '~/components/hooks/useAnalytics'

import styles from './QuickAddToCartButton.module.scss'

interface QuickAddToCartButtonProps {
	productName?: Optional<string>
	productId: string
	productStyleId?: Optional<string>
	setOutOfStockError: (arg: boolean) => void
	isWishlistPage?: boolean
	classNames?: string
	source?: QuickAddToCartData['quick_atb']['source']
}

const QuickAddToCartButton = ({
	productId,
	productName,
	productStyleId,
	classNames,
	source,
	setOutOfStockError,
}: QuickAddToCartButtonProps) => {
	const { addItems } = useCart()

	const formatMessage = useFormatMessage()
	const [isComplete, setIsComplete] = useState(false)
	const [isDisabled, setIsDisabled] = useState(false)

	const { analyticsManager } = useAnalytics()

	const createAnimation = (element: HTMLElement) => {
		const timeline = [{ strokeDashoffset: 138 }, { strokeDashoffset: 0 }]
		const options = {
			duration: 1300,
			iterations: 10,
			easing: 'ease-out',
		}
		const animation = element.animate(timeline, options)

		return animation
	}

	const handleAddSavedItemToCart = useCallback(
		async (productId: string) =>
			addItems([
				{
					id: productId,
					quantity: 1,
				},
			]).then((resp) => {
				if (resp.success) {
					setIsComplete(true)
					setTimeout(() => {
						setIsComplete(false)
					}, 1500)
					setIsDisabled(false)
				} else if (resp.errors.some((e) => e.code === CartProviderProductErrorCode.ITEM_UNAVAILABLE)) {
					setOutOfStockError(true)
				}
			}),
		[addItems, setOutOfStockError],
	)

	const handleClick = async (event: React.SyntheticEvent<HTMLButtonElement>) => {
		analyticsManager.trackQATBButtonClick(
			{
				product_name: productName || '',
				product_style: productStyleId || '',
				product_id: productId,
			},
			source,
		)

		const animation = createAnimation(event.target as HTMLElement)

		setIsDisabled(true)

		await handleAddSavedItemToCart(productId)

		animation.finish()
	}

	return (
		<button
			type="button"
			data-testid="quick-add-button"
			aria-label={formatMessage('quick-add-to-bag')}
			className={clsx(styles['quick-add-button'], classNames && classNames)}
			onClick={handleClick}
			disabled={isDisabled}
		>
			{isComplete ? (
				<span className={styles['check-icon']}>
					<CheckIcon />
				</span>
			) : (
				<QuickAddToCartIcon />
			)}

			<svg className={styles['quick-add-circle']} xmlns="http://www.w3.org/2000/svg" aria-hidden>
				<circle id="quick-add-circle-loader" />
			</svg>
		</button>
	)
}

export default QuickAddToCartButton
