import { memo, useMemo } from 'react'

interface ColorChipProps {
	/** ID used to distinguish image chips */
	id?: string

	/** Primary color */
	primary: string

	/** Secondary color */
	secondary?: string

	/** Hides the gray ring wrapping the color chip */
	hideRing?: boolean

	/** Circle or square with rounded corners */
	shape?: 'circle' | 'square'
}

interface ImageDetails {
	imageUrl: string
	yAxisOffset: number
}

const DEFAULT_PATTERN_ID = 'color-chip-image'

export default memo(function ColorChip({
	id,
	primary,
	secondary,
	hideRing,
	shape = 'circle',
}: ColorChipProps): React.ReactElement {
	const isSquare = shape === 'square'

	const commonPathProps = useMemo(
		() => ({
			strokeWidth: isSquare ? '1' : '6',
			stroke: hideRing ? 'transparent' : '#d0d0d0',
			viewBox: isSquare ? '0 0 32 32' : '0 0 100 100',
		}),
		[isSquare, hideRing],
	)

	const imagePatternId = useMemo(() => DEFAULT_PATTERN_ID.concat(id ? `-${id}` : ''), [id])
	const isImageBasedColor = useMemo(() => primary.includes('url('), [primary])
	const shapeFill = useMemo(
		() => (isImageBasedColor ? `url(#${imagePatternId})` : 'none'),
		[isImageBasedColor, imagePatternId],
	)

	/**
	 * Helper function that converts a string format for pixel declaration into a number.
	 * @param pixels string value for number of pixels (e.g. "70px")
	 * @returns Number interpreted from pixel string
	 */
	const convertPixelsToInt = (pixels: string): number => Number(pixels.replace('px', '')) ?? 0

	/**
	 * Helper function that extracts and converts a URL from the format passed by Salesforce Business Manager
	 * to a standard https:// format.
	 * @param url string url in the form: "url(//...)" (e.g. "url('//underarmour.scene7.com/is/image/Underarmour/refinement_texture_sprite?fmt=png8') 0 70px")
	 */
	const extractImageUrl = (url: string): string => {
		if (!url) return ''
		const urlWithoutPrefix = url.split(/\('\/\/(.*)'\)/)?.[1]
		return urlWithoutPrefix ? 'https://'.concat(urlWithoutPrefix) : ''
	}

	const imageDetails = useMemo((): ImageDetails | null => {
		if (!isImageBasedColor) return null

		const splicedUrl = primary.split(' ')
		return {
			imageUrl: splicedUrl?.[0] ?? primary,
			yAxisOffset: convertPixelsToInt(splicedUrl?.[2] ?? '0'),
		}
	}, [isImageBasedColor, primary])

	return (
		<svg data-color-chip width="100%" height="100%" viewBox={isSquare ? '0 0 32 32' : '0 0 100 100'} aria-hidden>
			{imageDetails && (
				<defs>
					<pattern id={imagePatternId} width="100%" height="100%" viewBox={`0 ${imageDetails.yAxisOffset} 32 32`}>
						<image href={extractImageUrl(imageDetails.imageUrl)} />
					</pattern>
				</defs>
			)}
			{isSquare ? (
				<>
					<path d="M 0 6 a 6 6 0 0 1 6 -6 h 10 v 32 h -10 a 6 6 0 0 1 -6 -6 Z" fill={primary} />
					<path d="M 16 0 h 10 a 6 6 0 0 1 6 6 v 20 a 6 6 0 0 1 -6 6 h -10 Z" fill={secondary || primary} />
					<rect x="0.5" y="0.5" width="31" height="31" rx="5.5" {...commonPathProps} fill={shapeFill} />
				</>
			) : (
				<>
					<path d="M50 3 A1 1, 0, 0 0, 50 97" fill={primary} />
					<path d="M50 3 A1 1, 0, 0 1, 50 97" fill={secondary || primary} />
					<circle cx="50%" cy="50%" r="50%" {...commonPathProps} fill={shapeFill} />
				</>
			)}
		</svg>
	)
})
