import { useMemo } from 'react'
import styles from './CarouselPagination.module.scss'
import clsx from 'clsx'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import type { Maybe } from 'graphql/jsutils/Maybe'

interface CarouselPaginationProps {
	onClick: (index: number) => void
	displayItems?: number
	slideCount: number
	step: 'page' | 'single'
	itemContainer: Maybe<HTMLDivElement>
}
export const CarouselPagination = ({
	step,
	itemContainer,
	displayItems,
	slideCount = 1,
	onClick,
}: CarouselPaginationProps) => {
	const formatMessage = useFormatMessage()

	const slideDotCount = useMemo(() => {
		switch (step) {
			case 'page':
				return displayItems ? Math.ceil(slideCount / displayItems) : slideCount
			default:
				return slideCount
		}
	}, [displayItems, slideCount, step])

	const activeIndex = useMemo(() => {
		const pageWidth = (itemContainer?.offsetWidth ?? 0) * slideDotCount
		const scrollPos = itemContainer?.scrollLeft ?? 0
		return Math.round((scrollPos / pageWidth) * slideDotCount)
	}, [itemContainer?.offsetWidth, itemContainer?.scrollLeft, slideDotCount])

	return (
		<div className={`carousel-pagination ${styles['carousel-pagination']}`}>
			{new Array(slideDotCount).fill(null).map((post, index: number) => (
				<button
					type={'button'}
					key={index}
					aria-current={index === activeIndex}
					aria-label={formatMessage('carousel-progress', {
						currentslide: index + 1,
						slidecount: slideDotCount,
					}).toString()}
					onClick={() => onClick(index)}
				>
					<span className={clsx(styles['grid-indicator'], { [styles['active-grid']]: activeIndex === index })} />
				</button>
			))}
		</div>
	)
}
