import 'swiper/css'
import 'swiper/css/navigation'

import clsx from 'clsx'
import { memo, useMemo } from 'react'
import { ensureArray } from 'types/strict-null-helpers'
import { useLocale } from '~/components/hooks/useLocale'
import ColorChip from '~/components/shared/ColorChip'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import type { PricedColorVariant } from '~/lib/types/search-suggestions.interface'
import { convertToHexString, getProductPath } from '~/lib/products'
import styles from './ColorCarousel.module.scss'
import { Carousel, CarouselItem } from '~/components/primitives/Carousel/Carousel'

export interface ColorCarouselProps {
	/* The list of product colors availabe */
	colors: PricedColorVariant[]

	/* The path (without the locale) to the product */
	productPath: string

	/* The style number for the product */
	productStyle: string

	/* The callback to call when a color swatch is hovered over */
	onColorHover: (color: PricedColorVariant) => void

	/* The callback to call when a color swatch is no longer being hovered over */
	onColorUnhover: () => void

	shape?: 'circle' | 'square'
}

/**
 * Displays the swatches associated with a product as they would appear in a product tile
 */
const ColorCarousel = ({
	colors,
	productPath,
	productStyle,
	onColorHover,
	onColorUnhover,
	shape = 'circle',
}: ColorCarouselProps) => {
	const locale = useLocale()
	const productColorsArr = useMemo(() => ensureArray(colors), [colors])
	const displayCount = shape === 'square' ? 9 : 6
	return (
		<div className={clsx(styles['carousel-container'], shape === 'square' && styles.square)}>
			<Carousel displayItems={displayCount}>
				{productColorsArr
					?.filter((color) => color.assets?.images?.[0])
					.map((color) => (
						<CarouselItem
							key={color.hex + color.color}
							onMouseOver={() => onColorHover(color)}
							onMouseOut={() => onColorUnhover()}
						>
							<LocaleLink
								href={getProductPath(locale, productPath, productStyle, color.color)}
								className={`${styles['product-color-swatch']} ${styles[`${shape}-color-swatch`]}`}
								aria-label={color?.name ?? undefined}
								title={`View Color ${color.name}-${color.color}`} // TODO: Translate this text
							>
								<ColorChip
									primary={convertToHexString(color.hex)}
									secondary={color.secondaryHex && convertToHexString(color.secondaryHex)}
									shape={shape}
								/>
							</LocaleLink>
						</CarouselItem>
					))}
			</Carousel>
		</div>
	)
}

export default memo(ColorCarousel)
