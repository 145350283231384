import styles from './RoundNavActions.module.scss'
import ArrowIcon from '~/components/primitives/icons/ArrowIcon'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import type { NavActionsProps } from '~/components/primitives/Carousel/NavActions'

export const RoundNavActions = ({
	onNextClick,
	onPrevClick,
	nextDisabled,
	prevDisabled,
	showPagination,
}: NavActionsProps) => {
	const formatMessage = useFormatMessage()
	return (
		<span data-pagination={showPagination} className={`round-nav-actions ${styles['slider-nav']}`} slot="container-end">
			<button type="button" onClick={onPrevClick} disabled={prevDisabled}>
				<span>{formatMessage('cms-slider-prev-slide')}</span>
				<ArrowIcon size="SM" />
			</button>
			<button type="button" onClick={onNextClick} disabled={nextDisabled}>
				<span>{formatMessage('cms-slider-next-slide')}</span>
				<ArrowIcon size="SM" />
			</button>
		</span>
	)
}
