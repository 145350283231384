import styles from './Carousel.module.scss'
import ArrowIcon from '~/components/primitives/icons/ArrowIcon'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import clsx from 'clsx'

export interface NavActionsProps {
	onNextClick: () => void
	onPrevClick: () => void
	nextDisabled: boolean
	prevDisabled: boolean
	showPagination: boolean
}

export const NavActions = ({
	onNextClick,
	onPrevClick,
	nextDisabled,
	prevDisabled,
	showPagination,
}: NavActionsProps) => {
	const formatMessage = useFormatMessage()
	return (
		<span data-pagination={showPagination} className={`arrow-nav-actions ${styles['slider-nav']}`} slot="container-end">
			<button
				className={clsx(styles.actions, styles['actions--prev-arrow'])}
				type="button"
				data-testid="prev-arrow"
				aria-disabled={prevDisabled}
				aria-label={formatMessage('previous-slide')}
				disabled={prevDisabled}
				onClick={onPrevClick}
				role="button"
			>
				<span className="visually-hidden">{formatMessage('previous-slide')}</span>
				<ArrowIcon className={clsx({ [styles['actions--disabled']]: prevDisabled })} size="SM" />
			</button>
			<button
				className={clsx(styles.actions, styles['actions--next-arrow'])}
				type="button"
				data-testid="next-arrow"
				aria-disabled={nextDisabled}
				aria-label={formatMessage('next-slide')}
				disabled={nextDisabled}
				onClick={onNextClick}
				role="button"
			>
				<span className="visually-hidden">{formatMessage('next-slide')}</span>
				<ArrowIcon className={clsx({ [styles['actions--disabled']]: nextDisabled })} size="SM" />
			</button>
		</span>
	)
}
