import clsx from 'clsx'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { useWishlist } from '~/components/providers/WishlistProvider/WishlistProvider'
import ColorCarousel from '~/components/shared/ColorCarousel/ColorCarousel'
import FavoriteButton from '~/components/shared/FavoriteButton/FavoriteButton'
import Image from '~/components/primitives/Image'
import { LocaleLink } from '~/components/primitives/LocaleLink/LocaleLink'
import MemberAccessBadge from '~/components/shared/MemberAccessBadge/MemberAccessBadge'
import PriceDisplay from '~/components/shared/PriceDisplay'
import ProductPromo from '~/components/shared/ProductPromo'
import type { Category, PricesRollup, ProductPromotion } from '~/graphql/generated/uacapi/type-document-node'
import { CategoryExperienceType, ExclusiveType } from '~/graphql/generated/uacapi/type-document-node'
import {
	convertToHexString,
	getColorDisplayCount,
	getDefaultColor,
	getProductColorCount,
	getTileImage,
	isEGiftCard,
	isProductOutOfStock,
} from '~/lib/products'
import type { ClientProductData, ClientProductTile } from '~/lib/types/product.interface'
import type { MappedWishListProduct } from '~/lib/types/wishlist.interface'
import type { Optional } from '~/types/strict-null-helpers'
import { ensureArray, ensureString, isArrayWithItems, isNonNullish } from '~/types/strict-null-helpers'
import ColorChip from '../ColorChip'
import styles from './ProductTile.module.scss'
import QuickAddToCartSavedItemsButton from './QuickAddToCartButton/QuickAddToCartButton'
import QuickAddToCartButton from './QuickAddToCartModal/QuickAddToCartButton'
import type { QuickAddToCartData } from '~/lib/types/analytics.interface'
import { SIZE_MAP } from '~/lib/size-like-mine'
import type { PricedColorVariant } from '~/lib/types/search-suggestions.interface'
import { ProductBadge } from '../ProductBadge/ProductBadge'
import { useLayoutMeasurement } from '~/components/hooks/useLayoutMeasurement'
import { useAbTest } from '~/components/providers/AbTestProvider/AbTestProvider'
import { useFeatureFlags } from '~/components/providers/CommerceConfigurationProvider/CommerceConfigurationProvider'
import { usePathname } from 'next/navigation'

export interface ProductTileClickData {
	id: string
	name: string
	style: string
}

export interface ProductTileProps {
	product: ClientProductTile | ClientProductData | MappedWishListProduct
	faceOutColors?: string[]
	wishListEnabled: boolean
	showQuickAddButton?: boolean
	onQuickAddButtonClick?: () => void
	isExperimentalView?: boolean
	extendedSizeFilters?: string
	className?: string
	showColorInfo?: boolean
	hasImagePriorityAsHigh?: boolean
	onClick?: (data: ProductTileClickData) => void
	category?: Category
	id?: string
	isWishlistPage?: boolean
	inSwiper?: boolean
	inModal?: boolean
	badgesEnabled?: boolean
	promotionsEnabled?: boolean
	isHollowImage?: boolean
	viewPreference?: string
	teamFilters?: string[]
	source?: QuickAddToCartData['quick_atb']['source']
	isZombie?: boolean
}

export const ProductTile = memo(function ProductTile({
	product,
	faceOutColors = [],
	wishListEnabled,
	showQuickAddButton = false,
	isExperimentalView = false,
	hasImagePriorityAsHigh = false,
	extendedSizeFilters,
	className,
	showColorInfo = true,
	onClick = () => false,
	onQuickAddButtonClick,
	category,
	id,
	inModal = false,
	isWishlistPage = false,
	inSwiper = false,
	badgesEnabled = true,
	promotionsEnabled = true,
	isHollowImage = false,
	viewPreference = '',
	teamFilters,
	source,
	isZombie = false,
}: ProductTileProps) {
	const { isQuickAddToCartEnabled: isQuickAddToCartFeatureEnabled } = useFeatureFlags() || {}
	const isQuickAddToCartAbEnabled = useAbTest('isCartQatbEnabled')
	const currentPath = usePathname()

	let isQuickAddToCartVisible = isQuickAddToCartFeatureEnabled
	if (currentPath.includes('/cart')) isQuickAddToCartVisible = !!isQuickAddToCartAbEnabled

	const { isMobile, isDesktop } = useLayoutMeasurement()
	const formatMessage = useFormatMessage()
	const [priority, setPriority] = useState(hasImagePriorityAsHigh)
	const { addProductToWishlist, removeProductFromWishlist, findWishlistProduct } = useWishlist()

	const isOutlet = category?.experienceType === CategoryExperienceType.OUTLET

	const defaultColor = useMemo(
		() => getDefaultColor(ensureArray(product.colors), faceOutColors, product, viewPreference, teamFilters),
		[faceOutColors, product, viewPreference, teamFilters],
	)
	const tileImage = useMemo(
		() => getTileImage(defaultColor, product, viewPreference, isHollowImage),
		[defaultColor, product, viewPreference, isHollowImage],
	)

	const totalColorCount = useMemo(() => getProductColorCount(product), [product])

	const mobileColorCountDisplay = useMemo(
		() => getColorDisplayCount({ totalColorCount, limit: isExperimentalView ? 3 : null, isDesktop }),
		[totalColorCount, isDesktop, isExperimentalView],
	)
	const colorCountMessage = useMemo(
		() => (isExperimentalView && !isDesktop ? 'remaining-colors' : 'colors-plural'),
		[isExperimentalView, isDesktop],
	)
	const defaultPrice = useMemo(() => product.price, [product.price])
	const defaultPromotion = useMemo(() => (product.productPromotions ? product.productPromotions : []), [product])

	const [src, setSrc] = useState<Optional<string>>(tileImage.url)
	const [price, setPrice] = useState<Optional<PricesRollup>>(defaultPrice)
	const [promotion, setPromotion] = useState<Optional<ProductPromotion[]>>(defaultPromotion)
	const [isVariantStockError, setVariantStockError] = useState(false)

	const comingSoon = product.exclusiveType === ExclusiveType.COMING_SOON
	const soldOut = product.exclusiveType === ExclusiveType.OUT_OF_STOCK
	const isOutOfStock = isProductOutOfStock(product) || isVariantStockError
	const isVariantProduct = isNonNullish(product.sku)

	const [imageError, setImageError] = useState(false)
	const handleMouse = useCallback((src: Optional<string>) => {
		setPriority(true)
		setSrc(src)
	}, [])

	useEffect(() => {
		setSrc(tileImage.url)
	}, [tileImage])

	useEffect(() => {
		// In some cases, the product will change from the server-generated content and the client-generated
		// product list. This forces us to rehydrate the price and promotion fields if the product varies
		// from server to client.
		setPrice(defaultPrice)
		setPromotion(defaultPromotion)
	}, [defaultPrice, defaultPromotion])

	const [isUpdatingFavorite, setIsUpdatingFavorite] = useState<boolean>(false)
	const handleToggleWishList = useCallback(
		async (e: React.ChangeEvent<HTMLInputElement>) => {
			const defaultVariant = product.colors?.find((color) => color.orderable)
			setIsUpdatingFavorite(true)
			if (e.target.checked) {
				await addProductToWishlist(product, defaultVariant?.color || '')
			} else {
				await removeProductFromWishlist(product, defaultVariant?.color || '')
			}
			setIsUpdatingFavorite(false)
		},
		[product, addProductToWishlist, removeProductFromWishlist],
	)

	const productLink = useCallback(() => {
		const url = product.url ? new URL(product.url, 'http://unused-base') : new URL('https://www.underarmour.com')
		if (extendedSizeFilters !== undefined) {
			url.searchParams.set('extendedSize', extendedSizeFilters)
		}

		if (viewPreference) {
			// ConstructorIO and UACAPI use different size IDs (e.g. CIO uses `S`,`M`,`L` versus
			// UACAPI's `SM`,`MD`,`LG`). Here, we catch the 'viewPrefence' and standardize it for
			// the PDP url so that there are no inconsistencies between our PLPs and PDPs.
			const standardizedPreference = SIZE_MAP[viewPreference]
				? viewPreference
				: Object.keys(SIZE_MAP).find((size) => SIZE_MAP[size].includes(viewPreference))
			const pdpViewPreference = standardizedPreference || viewPreference

			url.searchParams.set('viewPreference', pdpViewPreference)
		}

		if (product.isSliced) {
			url.searchParams.set(`dwvar_${product.style}_color`, product.colors?.at(0)?.color || '')
		} else if (defaultColor) {
			url.searchParams.set(`dwvar_${product.style}_color`, defaultColor.color)
		}

		return url.pathname + url.search
	}, [product, viewPreference, extendedSizeFilters, defaultColor])

	const handleColorChipHover = useCallback(
		(color: PricedColorVariant) => {
			const chipImage = getTileImage(color, product, viewPreference, isHollowImage)
			setSrc(chipImage.url)
			setPromotion(color.productPromotions ?? [])
			if (color.price) setPrice(color.price)
		},
		[product, viewPreference, isHollowImage],
	)

	const handleColorChipUnhover = useCallback(() => {
		setSrc(tileImage.url)
		setPrice(defaultPrice)
		setPromotion(defaultPromotion)
	}, [tileImage.url, defaultPrice, defaultPromotion])

	const tileContainerClasses = useMemo(
		() => [
			isZombie && styles['product-tile-zombie'],
			styles['product-tile-container'],
			'module__primary-img',
			inModal && styles['in-modal'],
			isExperimentalView ? styles['split-view-enabled'] : styles['split-view-disabled'],
			...(className ? [className] : []),
		],

		[isExperimentalView, className, inModal, isZombie],
	)

	const isExperimentalViewDesktop = isExperimentalView && !isMobile
	const isExperimentalViewMobile = isExperimentalView && isMobile

	const handleClick = useCallback(() => {
		onClick({ id: product.id, name: ensureString(product.name), style: ensureString(product.style) })
	}, [onClick, product.id, product.name, product.style])

	const memberExclusiveBadge = useMemo(() => {
		const loyaltyExclusiveColor = product.colors?.find((color) => color.isLoyaltyExclusiveColor === true)

		return !!loyaltyExclusiveColor
	}, [product])

	const isEGiftCardProduct = useMemo(() => isEGiftCard(product.giftCardType), [product])
	const isPromotionsShown = useMemo(
		() => !isWishlistPage && isArrayWithItems(promotion) && !inModal && promotionsEnabled,
		[isWishlistPage, promotion, promotionsEnabled, inModal],
	)

	// By default if viewing the wishlist page, the favorite icon should be filled. In all other contexts we just want to know if the master product is in the wishlist.
	const { isSavedItem } = useMemo(() => {
		return findWishlistProduct({ product })
	}, [product, findWishlistProduct])

	function fallbackImage(imageSrc: string | undefined) {
		if (isHollowImage && imageSrc) {
			const u = new URL(imageSrc)
			u.searchParams.set('bgc', 'FFFFFF')
			return u.toString()
		}
		return ensureString(imageSrc)
	}

	const isColorSwatchesShown = useMemo(() => showColorInfo && !inModal, [showColorInfo, inModal])

	return (
		<div id={id} className={tileContainerClasses.join(' ')} data-testid="product-tile-container">
			<LocaleLink
				href={productLink()}
				onClick={handleClick}
				className={clsx(
					styles['product-image-link'],
					!isColorSwatchesShown && styles['no-colors'],
					isWishlistPage && styles['is-wishlist'],
				)}
			>
				<Image
					src={!imageError ? ensureString(src) : fallbackImage(product.image?.url)} // TODO: We should use a default image here if src is invalid
					title={product?.name || undefined}
					alt={ensureString(product?.name || undefined)}
					layout="responsive"
					width={354}
					height={443}
					draggable={false}
					onMouseOver={() =>
						handleMouse(!imageError ? ensureString(tileImage.urlHov) : fallbackImage(product.image?.urlHov))
					}
					onMouseOut={() => handleMouse(!imageError ? ensureString(tileImage.url) : fallbackImage(product.image?.url))}
					sizes="(max-width: 1023px) 50vw,33vw"
					priority={priority}
					fetchPriority={priority ? 'high' : 'auto'}
					data-testid="tile-image"
					quality={!isHollowImage ? 30 : 80}
					onError={() => setImageError(true)}
				/>
			</LocaleLink>
			{isQuickAddToCartVisible &&
				showQuickAddButton &&
				!isEGiftCardProduct &&
				!memberExclusiveBadge &&
				product.style && (
					<div className={styles['quick-add-button']}>
						<QuickAddToCartButton
							productName={product.name}
							productId={product.id}
							productStyleId={product.style}
							defaultColor={product.colors?.[0]?.color}
							defaultExtendedSize={extendedSizeFilters}
							onButtonClick={onQuickAddButtonClick}
							source={source}
						/>
					</div>
				)}

			{!isOutOfStock && isWishlistPage && isVariantProduct && (
				<div className={styles['quick-add-button']}>
					<QuickAddToCartSavedItemsButton
						productId={product.id}
						productStyleId={product.style}
						setOutOfStockError={setVariantStockError}
						source={source}
					/>
				</div>
			)}

			{wishListEnabled &&
				!isEGiftCardProduct &&
				!product.isSliced && ( // TODO: remove sliced condition when this is fixed: https://underarmour.atlassian.net/browse/CSVT-644
					<div className={styles['wishlist-toggle']}>
						{/* If the request is in progress, we show the opposite state */}
						<FavoriteButton checked={isUpdatingFavorite ? !isSavedItem : isSavedItem} onChange={handleToggleWishList} />
					</div>
				)}
			{(product.badges?.upperLeft || product.badges?.upperLeftFlameIcon) &&
				badgesEnabled &&
				!(isExperimentalViewDesktop || inModal) && (
					<div className={styles['product-badge']}>
						<ProductBadge
							badges={product.badges}
							textPlacement={isExperimentalView || inModal ? 'inline' : 'top-left'}
							imageryPlacement="bottom-left-offset"
							textSize="small"
							isOutlet={isOutlet}
						/>
					</div>
				)}
			{isExperimentalViewMobile && (
				<h3>
					<LocaleLink
						href={productLink()}
						onClick={handleClick}
						className={clsx(styles['product-item-link'], inModal && styles.truncated)}
					>
						{product.name}
					</LocaleLink>
				</h3>
			)}
			{!isWishlistPage && product.colors && product.style && product.url && isColorSwatchesShown && (
				<div
					data-testid="product-swatches-container"
					className={clsx(
						styles['product-swatches-container'],
						isExperimentalView && styles['product-swatches-square'],
					)}
				>
					<div className={styles['product-swatches']}>
						<ColorCarousel
							colors={product.colors}
							productStyle={product.style}
							productPath={product.url}
							onColorHover={handleColorChipHover}
							onColorUnhover={handleColorChipUnhover}
							shape={isExperimentalView ? 'square' : 'circle'}
						/>
					</div>
					{product.colors && product.colors.length > 0 && (
						<LocaleLink
							href={productLink()}
							className={styles['product-color-count']}
							aria-label={formatMessage('colors-available-count', { totalCount: totalColorCount })}
						>
							{isExperimentalView &&
								product.colors.slice(0, 3).map((color, index) => (
									<div key={index} className={styles['product-color-chips']}>
										<ColorChip
											primary={convertToHexString(color.hex)}
											secondary={color.secondaryHex && convertToHexString(color.secondaryHex)}
											shape="square"
										/>
									</div>
								))}
							{mobileColorCountDisplay && (
								<p>
									<span>
										{formatMessage(colorCountMessage, {
											count: mobileColorCountDisplay,
										})}
									</span>
								</p>
							)}
						</LocaleLink>
					)}
					{memberExclusiveBadge && <MemberAccessBadge className={styles['member-exclusive-wrapper']} />}
				</div>
			)}
			{(isExperimentalViewDesktop || inModal) &&
				(product.badges?.upperLeft || product.badges?.upperLeftFlameIcon) &&
				badgesEnabled && (
					<div className={styles['product-badge']}>
						<ProductBadge
							badges={product.badges}
							textPlacement={isExperimentalView || inModal ? 'inline' : 'top-left'}
							imageryPlacement="bottom-left-offset"
							textSize="small"
							isOutlet={isOutlet}
						/>
					</div>
				)}
			{!isExperimentalViewMobile && (
				<h3>
					<LocaleLink
						href={productLink()}
						onClick={handleClick}
						className={clsx(styles['product-item-link'], inModal && styles.truncated)}
					>
						{product.name}
					</LocaleLink>
				</h3>
			)}

			{isWishlistPage && 'color' in product && 'size' in product && (
				<div className={styles['product-variant-info']}>
					<div>
						<>
							<span>{formatMessage('size')}:</span> {product.size}
						</>
					</div>
					<div>
						<>
							<span>{formatMessage('color')}:</span> {product.color}
						</>
					</div>
				</div>
			)}

			{price && (
				<div data-testid="product-item-price-section" className={styles['product-item-price-section']}>
					<PriceDisplay
						price={price}
						categoryExperienceType={category?.experienceType}
						productExperienceType={product?.experienceType}
						product={product}
						withPromotion={!!product.productPromotions?.length}
						isExperimentalView
						inModal={inModal}
					/>
				</div>
			)}
			{product.preorderable && (
				<div className={styles['product-preorder-message']}>
					<span>{product.tilePreorderMessage}</span>
				</div>
			)}
			{isOutOfStock && !isWishlistPage && (
				<div className={styles['product-out-of-stock']} data-testid="product-out-of-stock">
					<span>{formatMessage('sold-out')}</span>
				</div>
			)}
			{isPromotionsShown && (
				<ProductPromo
					productPromotions={promotion}
					className={styles['product-promo']}
					data-testid="product-promo"
					location={inSwiper ? 'top' : 'bottom'}
				/>
			)}
			{(comingSoon || soldOut) && (
				<div className={styles['coming-soon']}>
					<span>
						{(soldOut && formatMessage('sold-out')) || product.comingSoonMessage || formatMessage('coming-soon')}
					</span>
				</div>
			)}

			{isWishlistPage && isOutOfStock && (
				<div className={styles['saved-items-variant-out-of-stock-message']}>
					{isVariantProduct ? formatMessage('saved-items-variant-out-of-stock') : formatMessage('out-of-stock')}
				</div>
			)}
		</div>
	)
})

ProductTile.displayName = 'ProductTile'
