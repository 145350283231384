import { useCallback } from 'react'
import { formatMoney } from '~/lib/i18n/currency'
import { useLocale } from './useLocale'
import { useFeatureFlags } from '../providers/CommerceConfigurationProvider/CommerceConfigurationProvider'

export function useFormatMoney() {
	const locale = useLocale()
	const { hasPdpEnhancedProductName } = useFeatureFlags()
	return useCallback(
		(money: number, options = {}) => formatMoney(money, locale, options, hasPdpEnhancedProductName),
		[locale, hasPdpEnhancedProductName],
	)
}
