import type { ProductPromotion } from '~/graphql/generated/uacapi/type-document-node'
import type { ClientProductVariantDetail } from '~/lib/types/product.interface'
import type { Optional } from '~/types/strict-null-helpers'

import type { LocationDirection } from '~/components/primitives/Tooltip/Tooltip'
import ToolTip from '~/components/primitives/Tooltip/Tooltip'
import { useMemo } from 'react'

interface ProductPromoProps {
	productPromotions: Optional<ProductPromotion[]>
	selectedVariant?: ClientProductVariantDetail | undefined
	className?: string
	location?: LocationDirection
}

const ProductPromo: React.FC<ProductPromoProps> = ({ productPromotions, className, location = 'bottom' }) => {
	// There could be discounting promos without calloutMsg - looking for/displaying the first one with messaging
	const { calloutMsg, tooltip } = useMemo(
		() => productPromotions?.find((promo: ProductPromotion) => promo.calloutMsg) ?? {},
		[productPromotions],
	)

	if (!calloutMsg) {
		return null
	}

	return (
		<div className={className}>
			<span className="bfx-price">{calloutMsg}</span>
			{tooltip?.length && <ToolTip icon="question" location={location} body={(tooltip as string) ?? undefined} />}
		</div>
	)
}

export default ProductPromo
