import { memo } from 'react'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import styles from './MemberAccessBadge.module.scss'
import clsx from 'clsx'

function MemberAccessBadge({ className }: { className?: string }) {
	const formatMessage = useFormatMessage()

	return <span className={clsx(styles.badge, className)}>{formatMessage('member-access')}</span>
}

export default memo(MemberAccessBadge)
