import React, { useId, useMemo } from 'react'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import logger from '~/lib/logger'

import FavoriteIcon from '~/components/primitives/icons/FavoriteIcon'
import styles from './FavoriteButton.module.scss'

type IgnoredAttrs = Extract<
	keyof React.InputHTMLAttributes<HTMLInputElement>,
	'type' | 'hidden' | 'children' | 'onKeyDown' | 'aria-hidden' | 'aria-labelledby'
>

type FavoriteButtonProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, IgnoredAttrs>

/** Checkbox `input` displayed as a "Favorite Product" button */
const FavoriteButton = React.memo(
	React.forwardRef<HTMLInputElement, FavoriteButtonProps>(function FavoriteButton(
		{ id: idProp, className, style, 'aria-label': ariaLabel, onChange, ...attrs },
		ref,
	) {
		const uniqueId = useId()
		const id = useMemo(() => idProp ?? uniqueId, [idProp, uniqueId])
		const formatMessage = useFormatMessage()

		const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
			// if no aria, do custom label swapping
			if (!ariaLabel) {
				// change aria label if checked/unchecked
				const labelElement = document.getElementById(e.target.getAttribute('aria-labelledby') as string)
				if (labelElement) {
					labelElement.textContent = e.target.checked
						? formatMessage('favorite-button-selected')
						: formatMessage('favorite-button-not-selected')
				}
			}

			if (!onChange) {
				logger.warn('FavoriteButton: onChange handler not provided, no action will be taken.')
			} else {
				onChange(e)
			}
		}

		return (
			<>
				<input
					data-testid="favorite-button"
					ref={ref}
					id={id}
					className={styles['favorite-button']}
					type="checkbox"
					aria-labelledby={`${id}-label`}
					onKeyDown={handleKeydown}
					onChange={handleChange}
					{...attrs}
				/>

				<label className={className} style={style} htmlFor={id}>
					<span id={`${id}-label`} aria-hidden>
						{ariaLabel ||
							(attrs.checked
								? formatMessage('favorite-button-selected')
								: formatMessage('favorite-button-not-selected'))}
					</span>
					<span className={styles['icons-wrapper']}>
						<FavoriteIcon size="SM" className={styles['favorite-icon']} />
						<svg className={styles['favorite-ring']} xmlns="http://www.w3.org/2000/svg" aria-hidden>
							<circle />
						</svg>
					</span>
				</label>
			</>
		)
	}),
)

/** Causes `Enter` key inputs to activate the `checkbox` */
function handleKeydown(event: React.KeyboardEvent<HTMLInputElement>): void {
	if (event.key !== 'Enter' || event.repeat) return
	event.currentTarget.addEventListener('keyup', handleKeyup as EventListener, { once: true })

	function handleKeyup(innerEvent: KeyboardEvent & { currentTarget: HTMLInputElement }): void {
		if (innerEvent.key !== 'Enter') return
		innerEvent.currentTarget.click()
	}
}

export default FavoriteButton
