import { useCallback, useMemo } from 'react'
import { useFormatMoney } from '~/components/hooks/useFormatMoney'
import type { Maybe, PricesRollup } from '~/graphql/generated/uacapi/type-document-node'
import { CategoryExperienceType, ProductExperienceType } from '~/graphql/generated/uacapi/type-document-node'
import type { TotalPrice } from '~/lib/types/cart.interface'
import type { ClientProductData, ClientProductDetail, ClientProductTile } from '~/lib/types/product.interface'
import { ensureBoolean, forceNumber, isNonNullish } from '~/types/strict-null-helpers'

export interface FormatPriceProps {
	price: PricesRollup
	quantity?: number
	totalPrice?: TotalPrice
	categoryExperienceType?: CategoryExperienceType | null | undefined
	productExperienceType?: ProductExperienceType | null | undefined
	isEGiftCard?: boolean
	product?: ClientProductData | ClientProductTile | ClientProductDetail
	isPDP?: boolean
	isCheckout?: boolean
	isCart?: boolean
	withPromotion?: boolean
	fullPriceOnly?: boolean
}

export interface PriceDisplayItems {
	formattedListPrice: string
	formattedSalePrice: string
	showSalePrice: boolean
}

export function useFormatPrice({
	price,
	totalPrice,
	quantity = 1,
	categoryExperienceType = CategoryExperienceType.NONE,
	productExperienceType = ProductExperienceType.NONE,
	isEGiftCard,
	product,
	isPDP,
	isCheckout,
	isCart,
	withPromotion,
	fullPriceOnly = false,
}: FormatPriceProps): PriceDisplayItems {
	const formatMoney = useFormatMoney()
	const isCheckoutOrCart = isCheckout || isCart
	const shouldDisplayAsVariant = isCheckout || isCart || isPDP

	const formatPrice = useCallback(
		(price1?: Maybe<number>, price2?: Maybe<number>) => {
			if (isNonNullish(price1) && isNonNullish(price2) && price1 !== price2) {
				return `${formatMoney(Math.min(price1, price2))} - ${formatMoney(Math.max(price1, price2))}`
			}
			return formatMoney(forceNumber(price1))
		},
		[formatMoney],
	)

	const hasPromoAdjustedSalePrice = useMemo(() => {
		const saleMinValue = Math.min(forceNumber(price?.sale?.min), forceNumber(price?.sale?.max))
		return ensureBoolean(!isEGiftCard && totalPrice && totalPrice.totalAfterItemDiscount !== saleMinValue * quantity)
	}, [isEGiftCard, price, quantity, totalPrice])

	const hasPromotion = useMemo(() => {
		return ensureBoolean(
			hasPromoAdjustedSalePrice ||
				(product?.productPromotions && product?.productPromotions?.length !== 0) ||
				withPromotion,
		)
	}, [hasPromoAdjustedSalePrice, product, withPromotion])

	const showOnlyListPrice = useMemo(() => {
		if (shouldDisplayAsVariant) {
			return false
		}
		if (
			categoryExperienceType === CategoryExperienceType.PREMIUM &&
			(productExperienceType === ProductExperienceType.PREMIUM ||
				productExperienceType === ProductExperienceType.PREMIUM_MERCH_OVERRIDE ||
				productExperienceType === ProductExperienceType.BOTH)
		) {
			return true
		}
		if (
			productExperienceType === ProductExperienceType.PREMIUM ||
			productExperienceType === ProductExperienceType.PREMIUM_MERCH_OVERRIDE
		) {
			return price?.sale?.min !== price?.sale?.max || price?.list?.min !== price?.list?.max
		}
		return false
	}, [categoryExperienceType, price, productExperienceType, shouldDisplayAsVariant])

	const showPartialSale = useMemo(() => {
		if (fullPriceOnly) {
			return false
		}
		if (
			price?.sale?.min !== price?.sale?.max &&
			(price?.sale?.max === price?.list?.min || price?.sale?.max === price?.list?.max)
		) {
			return hasPromotion || !showOnlyListPrice
		}
		return false
	}, [hasPromotion, price, showOnlyListPrice, fullPriceOnly])

	const showSalePrice = useMemo(() => {
		if (fullPriceOnly) {
			return false
		}
		if (hasPromoAdjustedSalePrice) {
			return true
		}
		if ((price?.sale?.min === price?.list?.min && price?.sale?.max === price?.list?.max) || showPartialSale) {
			return false
		}
		return hasPromotion || !showOnlyListPrice
	}, [hasPromoAdjustedSalePrice, hasPromotion, fullPriceOnly, price, showOnlyListPrice, showPartialSale])

	const listPrice = useMemo(() => {
		if (isEGiftCard && totalPrice?.base) {
			return formatPrice(totalPrice.base)
		}
		if (showPartialSale) {
			return formatPrice(price?.sale?.min, price?.sale?.max)
		}
		return price?.list?.min === price?.list?.max
			? formatPrice(forceNumber(price?.list?.max) * (isCart || (isCheckout && showSalePrice) ? quantity : 1))
			: formatPrice(price?.list?.min, price?.list?.max)
	}, [formatPrice, isCart, isCheckout, isEGiftCard, price, quantity, showPartialSale, showSalePrice, totalPrice])

	const salePrice = useMemo(() => {
		if (isCheckoutOrCart && totalPrice && hasPromoAdjustedSalePrice) {
			return formatPrice(totalPrice.totalAfterItemDiscount / (isCheckout ? quantity : 1))
		}
		return price?.sale?.min === price?.sale?.max
			? formatPrice(forceNumber(price?.sale?.max) * (isCart ? quantity : 1))
			: formatPrice(price?.sale?.min, price?.sale?.max)
	}, [formatPrice, hasPromoAdjustedSalePrice, isCart, isCheckout, isCheckoutOrCart, price, quantity, totalPrice])

	return {
		formattedListPrice: listPrice,
		formattedSalePrice: salePrice,
		showSalePrice,
	}
}
