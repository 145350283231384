import { memo } from 'react'

import clsx from 'clsx'
import type { FormatPriceProps } from '~/components/hooks/useFormatPrice'
import { useFormatPrice } from '~/components/hooks/useFormatPrice'
import { CategoryExperienceType, ProductExperienceType } from '~/graphql/generated/uacapi/type-document-node'

import { useFormatMessage } from '../hooks/useFormatMessage'
import styles from './PriceDisplay.module.scss'
import { useFeatureFlags } from '../providers/CommerceConfigurationProvider/CommerceConfigurationProvider'

export type PriceDisplayProps = {
	showCartQuantity?: boolean
	isExperimentalView?: boolean
	inModal?: boolean
} & FormatPriceProps

function PriceDisplay({
	price,
	totalPrice,
	quantity = 1,
	categoryExperienceType = CategoryExperienceType.NONE,
	productExperienceType = ProductExperienceType.NONE,
	isEGiftCard,
	product,
	isPDP,
	isCheckout,
	isCart,
	showCartQuantity,
	withPromotion,
	fullPriceOnly,
	isExperimentalView = false,
	inModal = false,
}: PriceDisplayProps) {
	const formatMessage = useFormatMessage()
	const isCheckoutOrCart = isCheckout || isCart

	const {
		formattedListPrice: listPrice,
		formattedSalePrice: salePrice,
		showSalePrice,
	} = useFormatPrice({
		price,
		totalPrice,
		quantity,
		categoryExperienceType,
		productExperienceType,
		isEGiftCard,
		product,
		isPDP,
		isCheckout,
		isCart,
		withPromotion,
		fullPriceOnly,
	})

	const { isEnhancedPDPenabled, hasPdpEnhancedProductName: hasEnhancedProductName } = useFeatureFlags()

	return (
		<>
			{showCartQuantity && (
				<div className={styles['quantity-line']}>
					<span data-testid="price-display-quantity">{formatMessage('cart-items', { quantity })}</span>
				</div>
			)}
			<div
				className={clsx(styles['price-display'], {
					[styles['price-display-cart']]: isCheckoutOrCart,
					[styles['alternate-price-display']]:
						hasEnhancedProductName || (isEnhancedPDPenabled && isPDP) || isExperimentalView,
					[styles['in-modal']]: isExperimentalView && inModal,
					[styles['enhanced-price-display']]: isPDP && hasEnhancedProductName,
				})}
				aria-hidden={true}
			>
				<span data-testid="price-display-list-price" className="bfx-price bfx-list-price">
					{listPrice}
				</span>
				{showSalePrice && (
					<span data-testid="price-display-sales-price" className="bfx-price bfx-sale-price">
						{salePrice}
					</span>
				)}
			</div>
			<div className={styles['sr-price']}>
				{showSalePrice && formatMessage('sale-price-label', { price: listPrice, sale: salePrice })}
				{!showSalePrice && formatMessage('price-label', { price: listPrice })}
			</div>
			{quantity && isCheckout && (
				<span data-testid="product-quantity" className={clsx(styles.quantity, { [styles.promo]: showSalePrice })}>
					{/* eslint-disable-line local-rules/disallow-untranslated-literals-in-jsx */} x {quantity}
				</span>
			)}
		</>
	)
}

export default memo(PriceDisplay)
